import type { AppType } from 'next/dist/shared/lib/utils'

import { withTRPC } from '@trpc/next'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import superjson from 'superjson'
import {
  FutureverseAuthClient,
  UserState,
  TrnApiProvider,
} from '@futureverse/react'
import dynamic from 'next/dynamic'

import type { AppRouter } from 'server/router'

import { getBaseUrl, ENVIRONMENT } from 'utils/config'
import { FPASS_STAGE } from 'utils/constants'
import { UserProvider } from 'utils/user'

import '../styles/globals.scss'

const URI_BASE = typeof window !== 'undefined' ? window.location.origin : ''

const FutureverseProvider = dynamic(
  () => import('@futureverse/react').then((mod) => mod.FutureverseProvider),
  {
    ssr: false,
  }
)

const authClient = (() => {
  const client = new FutureverseAuthClient({
    clientId: process.env.NEXT_PUBLIC_FUTUREPASS_CLIENT_ID!,
    environment: ENVIRONMENT,
    redirectUri: `${URI_BASE}/futurepass`,
  })
  client.addUserStateListener((userState) => {
    if (userState === UserState.SignedOut) {
      sessionStorage.setItem('fvAuthSilentLogin', 'disabled')
    }
  })
  return client
})()

const MyApp: AppType = ({
  Component,
  pageProps,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageProps: any
}) => {
  return (
    <>
      <GoogleAnalytics trackPageViews />
      <FutureverseProvider
        stage={FPASS_STAGE}
        authClient={authClient}
        Web3Provider="wagmi"
        requiredChains={['ETHEREUM', 'TRN']}
        walletConnectProjectId="00db47b27daa8001775b187c5353f8e6"
        isXamanLoginEnabled={true}
        isCustodialLoginEnabled={true}
      >
        <TrnApiProvider>
          <UserProvider>
            <Component {...pageProps} />
          </UserProvider>
        </TrnApiProvider>
      </FutureverseProvider>
    </>
  )
}

export default withTRPC<AppRouter>({
  config() {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    const url = `${getBaseUrl()}/api/trpc`

    return {
      url,
      transformer: superjson,
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      queryClientConfig: {
        defaultOptions: { queries: { refetchOnWindowFocus: false } },
      },
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: false,
})(MyApp)
